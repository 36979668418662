import React, { useMemo } from "react"

import { useCore } from "../../../hooks/useCore"

export const withReset = Component =>
  React.memo(({ name = "Reset", page, location }) => {
    const {
      helpers: { sanityContent },
    } = useCore()

    const title = page?.title
    const content = sanityContent(page?.content)

    Component.displayName = name
    return useMemo(() => <Component title={title} content={content} location={location} />, [])
  })
