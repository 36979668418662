import React from "react"

import { withResetForm } from "./withResetForm"
import { Form, Description, Field, Button, ButtonWrapper } from "../../Form/Form.styled"

export const ResetForm = withResetForm(
  ({ data, handleChange, handleSubmit, loading, errors, content }): JSX.Element => (
    <Form onSubmit={handleSubmit}>
      {content && errors?.length < 1 ? <Description>{content}</Description> : null}
      {errors?.length > 0 && (
        <Description>
          <ul>
            {errors?.map((error, index) => (
              <li key={index}>
                <p>{error?.message}</p>
              </li>
            ))}
          </ul>
        </Description>
      )}
      <Field name={"password"} type={"password"} placeholder={"Enter new password"} value={data?.password} onChange={handleChange} required />
      <ButtonWrapper center={true}>
        <Button type="submit" center={true} size={`large`} colour={`dark`} theme={`primary`} loading={loading} disabled={data?.password?.length < 8}>
          Update Password
        </Button>
      </ButtonWrapper>
    </Form>
  )
)
