import React from "react"

import { withReset } from "./withReset"
import { ResetForm } from "./ResetForm"
import { Container, Frame, Title } from "../../Form/Form.styled"

export const Reset = withReset(
  ({ title, content, location }): JSX.Element => (
    <Container width={`md`}>
      <Frame>
        <Title description={content}>{title}</Title>
        <ResetForm content={content} location={location} />
      </Frame>
    </Container>
  )
)
