import React, { useMemo } from "react"

import { useCustomerAccount } from "../../../hooks/useCustomer"

export const withResetForm = Component =>
  React.memo(({ name = "ResetForm", location, content }) => {
    const { resetCustomer, data, setData, loading, errors } = useCustomerAccount()

    const { customerId, resetToken, initError } = React.useMemo(() => {
      const resetParams = location?.pathname?.split("/account/reset/")[1]?.split("/") || []

      const customerId = (resetParams && resetParams[0]) || null
      const resetToken = (resetParams && resetParams[1]) || null

      let initError = null

      if (!customerId || !resetToken) {
        initError = "Your reset password link is invalid. Please click the link in your email again."
      }

      return { customerId, resetToken, initError }
    }, [])

    const handleSubmit = async event => {
      event.preventDefault()
      await resetCustomer(customerId, resetToken, data?.password)
    }

    const handleChange = ({ target: { type, name, value, checked } }) => {
      setData(prevState => ({
        ...prevState,
        [name]: type === "checkbox" ? checked : value,
      }))
    }

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          content={content}
          data={data}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          loading={loading}
          initError={initError}
          errors={errors}
        />
      ),
      [data, loading, errors]
    )
  })
